<template>
  <div class="content">
    <app-cells position="between">
      <h1 class="title title--theme">Службы</h1>
      <v-select
        v-if="userIsAdmin"
        :reduce="item => item.id"
        :options="delegation_options"
        :filterable="true"
        :clearable="false"
        v-model="user_delegation_id"
        @input="onSetDelegation"
        label="name"
        placeholder="Выберите делегацию"
        class="select select--minw"
      >
        <template #open-indicator>
          <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
            <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
          </svg>
        </template>
        <template #option="option">
          <div class="select__item d-center">{{ option.name }}</div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">{{ option.name }}</div>
        </template>
        <template #no-options>Ничего не найдено</template>
      </v-select>
    </app-cells>
    <app-tabs :tabs="tabs" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import {getDelegations, patchUser} from '@/http'

export default {
  name: 'DirectorateIndex',
  data() {
    return {
      tabs: [
        { name: 'directorate-list', label: 'Организаторы' },
        { name: 'experts-list', label: 'Эксперты' },
      ],
      delegation_options: [],
      user_delegation_id: null,
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            }
          })
      }
    }, 200)
  },
  methods: {
    onSetDelegation() {
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItems()
          this.fetchDelegationsSettings()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
  }
}
</script>
